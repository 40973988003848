import { IconBaseProps, IconType } from "react-icons";

export const HolodexLogo: IconType = (props: IconBaseProps) => {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 49.53 46.74"
      {...props}
    >
      <g>
        <path
          d="M47.41 19.71C48.0536 20.0816 48.588 20.616 48.9595 21.2595C49.3311 21.9031 49.5267 22.6331 49.5267 23.3762C49.5267 24.1193 49.3311 24.8494 48.9595 25.4929C48.588 26.1365 48.0536 26.6709 47.41 27.0424L14.283 46.1684C13.6394 46.54 12.9094 46.7356 12.1663 46.7356C11.4232 46.7356 10.6932 46.54 10.0496 46.1684C9.40608 45.7969 8.87166 45.2625 8.50011 44.6189C8.12857 43.9754 7.93297 43.2453 7.93298 42.5022V4.25023C7.93297 3.50712 8.12857 2.77709 8.50011 2.13353C8.87166 1.48997 9.40608 0.955557 10.0496 0.584C10.6932 0.212442 11.4232 0.0168418 12.1663 0.0168457C12.9094 0.0168496 13.6394 0.212466 14.283 0.58403"
          fill="#F06292"
        ></path>
        <path
          d="M39.48 19.69C40.1236 20.0615 40.658 20.596 41.0296 21.2395C41.4011 21.8831 41.5967 22.6131 41.5967 23.3562C41.5967 24.0993 41.4011 24.8293 41.0296 25.4729C40.658 26.1165 40.1236 26.6509 39.48 27.0224L6.353 46.1484C5.70945 46.52 4.97943 46.7156 4.23632 46.7156C3.49321 46.7156 2.76319 46.52 2.11964 46.1484C1.47608 45.7769 0.94167 45.2425 0.570122 44.5989C0.198573 43.9553 0.00297606 43.2253 0.00299072 42.4822V4.23021C0.00297606 3.4871 0.198573 2.75708 0.570122 2.11353C0.94167 1.46997 1.47608 0.935538 2.11964 0.56398C2.76319 0.192422 3.49321 -0.00317776 4.23632 -0.00317383C4.97943 -0.00316989 5.70945 0.192446 6.353 0.564011"
          fill="#64B5F6"
        ></path>
      </g>
    </svg>
  );
};
